<template>
  <div class="modal-overlay" @click="handleOverlayClick">
    <div class="modal-content">
      <h2>Обновить Телеграм Сессию</h2>
      <div v-if="!codeSent">
        <p>Отправка кода на ваш номер телефона...</p>
      </div>
      <div v-else>
        <h3>Введите новый код подтверждения</h3>
        <form @submit.prevent="validateAndCompleteRefresh">
          <div class="form-group">
            <label for="code">Код из Telegram</label>
            <input
              type="text"
              v-model="code"
              id="code"
              required
              @input="validateCode"
            />
            <span v-if="errors.code" class="error">{{ errors.code }}</span>
          </div>
          <!-- Новое поле для облачного пароля -->
          <div class="form-group">
            <label for="cloud_password">Облачный пароль (если установлен)</label>
            <input
              type="password"
              v-model="cloudPassword"
              id="cloud_password"
            />
            <span v-if="errors.cloudPassword" class="error">{{ errors.cloudPassword }}</span>
          </div>
          <button
            type="submit"
            class="action-button create"
            :disabled="isSubmitting"
          >
            Завершить обновление
          </button>
        </form>
      </div>
      <button @click="closeModal" class="action-button close">Закрыть</button>
    </div>
  </div>
</template>

<script>
import apiClient from "../services/apiClient";
import forge from "node-forge";

export default {
  props: {
    sessionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      codeSent: false,
      code: "",
      cloudPassword: "",
      errors: {
        code: "",
        cloudPassword: "",
      },
      isSubmitting: false,
      publicKey: null,
    };
  },
  methods: {
    handleOverlayClick(event) {
      if (event.target === event.currentTarget) {
        if (confirm("Вы уверены, что хотите закрыть окно?")) {
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async fetchPublicKey() {
      try {
        const response = await apiClient.get("/public-key");
        this.publicKey = forge.pki.publicKeyFromPem(response.data.public_key);
      } catch (error) {
        console.error("Ошибка при получении публичного ключа:", error);
        alert("Не удалось получить публичный ключ.");
      }
    },
    encryptData(data) {
      const aesKey = forge.random.getBytesSync(32);
      const iv = forge.random.getBytesSync(16);

      const cipher = forge.cipher.createCipher("AES-CBC", aesKey);
      cipher.start({ iv });
      cipher.update(forge.util.createBuffer(data));
      cipher.finish();
      const encryptedData = cipher.output.getBytes();

      const encryptedKey = this.publicKey.encrypt(aesKey, "RSA-OAEP", {
        md: forge.md.sha256.create(),
        mgf1: forge.md.sha256.create(),
      });

      return {
        encryptedData: forge.util.encode64(encryptedData),
        encryptedKey: forge.util.encode64(encryptedKey),
        iv: forge.util.encode64(iv),
      };
    },
    validateCode() {
      this.errors.code = this.code.length > 0 ? "" : "Код не может быть пустым";
    },
    async startRefresh() {
      this.isSubmitting = true;
      try {
        const response = await apiClient.post(
          `/refresh-session/${this.sessionId}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

        if (response.data.status === "code_sent") {
          this.codeSent = true;
        } else {
          alert("Произошла ошибка: " + response.data.detail);
        }
      } catch (error) {
        console.error("Ошибка при обновлении сессии:", error);
        alert("Произошла ошибка при обновлении сессии.");
        this.closeModal();
      } finally {
        this.isSubmitting = false;
      }
    },
    async validateAndCompleteRefresh() {
      this.validateCode();

      if (this.errors.code === "" && this.errors.cloudPassword === "") {
        await this.completeRefresh();
      }
    },
    async completeRefresh() {
      this.isSubmitting = true;
      try {
        const encryptedCode = this.encryptData(this.code);

        // Формируем объект данных для отправки
        const data = {
          session_id: this.sessionId,
          code: encryptedCode,
        };

        // Если облачный пароль введён, шифруем и добавляем его в запрос
        if (this.cloudPassword) {
          const encryptedCloudPassword = this.encryptData(this.cloudPassword);
          data.cloud_password = encryptedCloudPassword;
        }

        const response = await apiClient.post("/complete-refresh", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        if (response.data.status === "authorized") {
          alert("Сессия успешно обновлена.");
          this.$emit("refreshed");
          this.closeModal();
        } else {
          alert("Произошла ошибка: " + response.data.detail);
        }
      } catch (error) {
        console.error("Ошибка при завершении обновления сессии:", error);
        alert("Произошла ошибка при завершении обновления сессии.");
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  async mounted() {
    document.body.style.overflow = "hidden";
    await this.fetchPublicKey();
    await this.startRefresh();
  },
  beforeUnmount() {
    document.body.style.overflow = "";
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Более темный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px); /* Размытие заднего фона */
}

.modal-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  width: 100%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15); /* Похожие тени */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 500px; /* Убедитесь, что контейнер имеет максимальную ширину */
  margin: 0 auto;
}

h2,
h3 {
  text-align: center;
  color: #2980b9; /* Соответствие цветовой схеме */
}

.form-group {
  margin-bottom: 20px;
  position: relative; /* Для предотвращения смещения */
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555555;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #cccccc;
  transition: border-color 0.3s ease;
  margin: 0; /* Сброс отступов */
  box-sizing: border-box; /* Убедитесь, что ширина включает padding */
  text-align: left; /* Сброс выравнивания */
}

.form-group input:focus {
  border-color: #2980b9; /* Цвет фокуса, соответствующий цветам темы */
  outline: none;
}

.error {
  color: #e74c3c;
  font-size: 0.85em;
  margin-top: 5px;
  height: 1em; /* Добавлено для фиксации высоты */
  line-height: 1em;
}

.action-button {
  width: 100%;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.action-button.create {
  background-color: #28a745;
  color: #ffffff;
}

.action-button.create:hover:enabled {
  background-color: #218838;
}

.action-button.close {
  background-color: #e74c3c;
  color: white;
}

.action-button.close:hover {
  background-color: #c0392b;
}

@media (max-width: 600px) {
  .modal-content {
    width: 90%;
  }
}
</style>